import axios, { AxiosResponse } from 'axios'
import authHeader from './auth.service'
import { HistoryEntry, HistoryFeedback } from '../types/history.types'

const HISTORY_URL = (process.env.REACT_APP_API_URL || 'http://localhost:8000/') + 'history/'

export const deleteHistoryEntry = async (historyId: number) => {
    return await axios.delete(HISTORY_URL + historyId, {
        headers: authHeader(),
    })
}

export const getLatestHistoryId = async (): Promise<AxiosResponse<number>> => {
    return await axios.get(HISTORY_URL + 'latest', {
        headers: authHeader(),
    })
}

export const getPagedUserHistory = async (pageNumber: number): Promise<AxiosResponse<HistoryEntry[]>> => {
    return await axios.get(HISTORY_URL + 'page/' + pageNumber, {
        headers: authHeader(),
    })
}

export const sendHistoryFeedback = async (historyId: number, feedback: HistoryFeedback) => {
    console.log(JSON.stringify(historyId))
    const url = HISTORY_URL + String(historyId) + '/feedback'
    console.log(url)
    return await axios.patch(url, feedback, { headers: authHeader() })
}

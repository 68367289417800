import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import * as Yup from 'yup'

import { createUser } from '../services/user.service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const possibleErrors = {
    emailRequired: 'emailRequired',
    faultyUsername: 'faultyUsername',
    faultyPassword: 'faultyPassword',
}
const Register: React.FC = () => {
    function Copyright(props: any) {
        return (
            <Typography variant='body2' color='text.secondary' align='center' {...props}>
                {'Copyright © '}
                <Link color='inherit' href='https://text2knowledge.de/'>
                    Text2Knowledge
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        )
    }

    const [t, _i18n] = useTranslation()
    const [successful, setSuccessful] = useState<boolean | undefined>()
    const [userNameOk, setUserNameOk] = useState<boolean>(true)
    const [mailOk, setMailOk] = useState<boolean>(true)
    const [mailExists, setMailExists] = useState<boolean>(false)
    const [passwordOk, setPasswordOk] = useState<boolean>(true)
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [mail, setMail] = useState<string>('')
    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .test(
                'len',
                possibleErrors.faultyUsername,
                (val: any) => val && val.toString().length >= 3 && val.toString().length <= 20,
            )
            .required(possibleErrors.faultyUsername),
        mail: Yup.string().email(possibleErrors.emailRequired).required(possibleErrors.emailRequired),
        password: Yup.string()
            .test(
                'len',
                possibleErrors.faultyPassword,
                (val: any) => val && val.toString().length >= 6 && val.toString().length <= 40,
            )
            .required(possibleErrors.faultyPassword),
    })

    const handleRegister = async () => {
        validationSchema
            .isValid({
                username: username,
                mail: mail,
                password: password,
            })
            .then((valid: boolean) => {
                if (valid) {
                    setUserNameOk(true)
                    setMailOk(true)
                    setPasswordOk(true)
                    createUser(username, mail, password)
                        .then((_response) => {
                            setSuccessful(true)
                            setUsername('')
                            setPassword('')
                            setMail('')
                        })
                        .catch((reason) => {
                            console.log(reason)
                            if (reason.response.status === 409) {
                                setSuccessful(false)
                                setMailExists(true)
                            }
                        })
                } else {
                    setSuccessful(false)
                    // check which is wrong
                    validationSchema
                        .validate(
                            {
                                username: username,
                                mail: mail,
                                password: password,
                            },
                            { abortEarly: false },
                        )
                        .catch((err: any) => {
                            console.log(err.errors)
                            setMailExists(false)
                            setUserNameOk(!err.errors.includes(possibleErrors.faultyUsername))
                            setMailOk(!err.errors.includes(possibleErrors.emailRequired))
                            setPasswordOk(!err.errors.includes(possibleErrors.faultyPassword))
                        })
                }
            })
    }

    return (
        <Container component='main'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    {t('Sign up')}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='username'
                                name='username'
                                required
                                fullWidth
                                id='username'
                                label={t('Username')}
                                autoFocus
                                value={username}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUsername(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id='email'
                                label={t('E-Mail Address')}
                                name='email'
                                autoComplete='email'
                                value={mail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setMail(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name='password'
                                label={t('Password')}
                                type='password'
                                id='password'
                                autoComplete='new-password'
                                value={password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPassword(event.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button onClick={handleRegister} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        {t('Sign up')}
                    </Button>
                    {successful === false && (
                        <>
                            {!userNameOk && (
                                <Alert severity='error'>{t('The username must be between 3 and 20 characters.')}</Alert>
                            )}
                            {!mailOk && <Alert severity='error'>{t('This is not a valid email.')}</Alert>}
                            {!passwordOk && (
                                <Alert severity='error'>{t('The password must be between 6 and 40 characters.')}</Alert>
                            )}
                            {mailExists && <Alert severity='error'>{t('User with that E-Mail already exists.')}</Alert>}
                        </>
                    )}
                    {successful && (
                        <>
                            <Alert severity='success'>{t('User was successfully created.')}</Alert>
                        </>
                    )}
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Link href='#' onClick={() => navigate('/login')} variant='body2'>
                                {t('Already have an account? Sign in')}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    )
}

export default Register

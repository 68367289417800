import { Checkbox, Container, Divider, Grid, ListItemButton, ListItemText } from '@mui/material'
import { HistoryEntry } from '../types/history.types'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import React from 'react'

type HistoryEntryComponentProps = {
    historyEntry: HistoryEntry
    handleHistory: (entry: HistoryEntry) => void
    deleteHistoryEntry: (entry: HistoryEntry) => void
    historyEntryIndex: number
}

export const HistoryEntryComponent: React.FC<HistoryEntryComponentProps> = ({
    historyEntry,
    handleHistory,
    deleteHistoryEntry,
    historyEntryIndex,
}) => {
    return (
        <Container>
            <ListItemButton>
                <Grid container>
                    <Grid item xs={12} onClick={() => handleHistory(historyEntry)}>
                        <ListItemText
                            primary={
                                historyEntryIndex +
                                '. ' +
                                historyEntry.inputText.substring(0, 75) +
                                (historyEntry.inputText.length > 75 ? '...' : '')
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {historyEntry.feedbackIsPositive !== null ? (
                            historyEntry.feedbackIsPositive ? (
                                <Checkbox disabled icon={<ThumbUpOffAltIcon />} />
                            ) : (
                                <Checkbox disabled icon={<ThumbDownOffAltIcon />} />
                            )
                        ) : (
                            ''
                        )}
                        <Checkbox
                            icon={<DeleteOutlineIcon />}
                            onClick={() => deleteHistoryEntry(historyEntry)}
                            checked={false}
                        />
                    </Grid>
                </Grid>
            </ListItemButton>
            <Divider />
        </Container>
    )
}

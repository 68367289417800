import { SimplificationResponse, SimplificationRequest } from '../types/simplification.types'
import { getLatestHistoryId } from './history.service'
import authHeader from './auth.service'

const SIMPLIFICATION_URL = (process.env.REACT_APP_API_URL || 'http://localhost:8000/') + 'simplify'

const prepareText = (text: string | undefined): string => {
    return text?.replaceAll('[AUFZ]', '\n•').replaceAll('[ENDAUFZ]', '\n').replaceAll('•\n', '') || ''
}

// Helper to process decoded JSON segments
const processDecodedChunks = (decodedChunk: string): any[] => {
    const segments = decodedChunk.split('}{')

    // Restore removed separators by ensuring each segment starts and ends with correct braces
    for (let i = 0; i < segments.length; i++) {
        if (!segments[i].startsWith('{')) {
            segments[i] = '{' + segments[i]
        }
        if (!segments[i].endsWith('}')) {
            segments[i] = segments[i] + '}'
        }
    }

    // Parse the segments into JSON objects
    return segments
        .map((segment) => {
            try {
                return JSON.parse(segment)
            } catch (error) {
                console.error('Error parsing JSON segment:', error, segment)
                return null
            }
        })
        .filter((parsed) => parsed !== null)
}

export const simplifyRequest = async (
    textCallback: React.Dispatch<React.SetStateAction<string>>,
    stateRemovalCallback: (status: boolean) => void,
    historyIdCallback: (historyId: number | undefined) => void,
    data: SimplificationRequest,
) => {
    try {
        const response = await fetch(SIMPLIFICATION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify(data),
        })

        if (!response.body) throw new Error('No response body available.')

        const reader = response.body.getReader()
        let processStream = await reader.read()
        const decoder = new TextDecoder()
        let text = ''

        // Process the stream
        while (!processStream.done) {
            const decodedChunk = decoder.decode(processStream.value, { stream: true })
            const parsedObjects = processDecodedChunks(decodedChunk)

            // Handle each parsed JSON object
            for (const jsonObject of parsedObjects) {
                const result = new SimplificationResponse(jsonObject.result, jsonObject.tookTime)
                text += prepareText(result.result)
                textCallback(text)
            }

            // Read the next chunk
            processStream = await reader.read()
        }

        // Stream is done, update history ID and clean up
        const historyIdResponse = await getLatestHistoryId()
        historyIdCallback(historyIdResponse.data)
        stateRemovalCallback(false)
    } catch (error) {
        console.error('Error during simplification request:', error)
        stateRemovalCallback(false) // Ensure cleanup on error as well
    }
}

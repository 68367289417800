import styled, { css } from 'styled-components'
import { acceptedExt, checkType, getFileSizeMB } from '../functions/utils'
import React, { useEffect, useRef, useState } from 'react'

import useDragging from '../functions/useDragging'
import DrawTypes from '../types/Drawtypes'
import UploadRoundedIcon from '@mui/icons-material/UploadRounded'
import { useTranslation } from 'react-i18next'

const defaultStyle = css`
    display: flex;
    align-items: center;
    max-width: 110vh;
    height: 60px;
    border: dashed 2px grey;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 0;
`

const UploaderWrapper = styled.label<any>`
    position: relative;
    ${(props) => (props.overRide ? '' : defaultStyle)};
    &:focus-within {
        outline: 2px solid black;
    }
    & > input {
        display: block;
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
`

const HoverMsg = styled.div`
    border: dashed 2px #f5f5f5;
    border-radius: 5px;
    background-color: #f5f5f5;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    & > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`

const DescriptionWrapper = styled.div<{ error: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    & > span {
        font-size: 14px;
        color: grey;
    }
    .file-types {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
    }
`

type Props = {
    name?: string
    hoverTitle?: string
    types?: Array<string>
    classes?: string
    children?: JSX.Element
    maxSize?: number
    minSize?: number
    fileOrFiles?: Array<File> | File | null
    multiple?: boolean | false
    required?: boolean | false
    onSizeError?: (arg0: string) => void
    onTypeError?: (arg0: string) => void
    onDrop?: (arg0: File | Array<File>) => void
    onSelect?: (arg0: File | Array<File>) => void
    handleChange?: (arg0: File | Array<File> | File) => void
    onDraggingStateChange?: (dragging: boolean) => void
    dropMessageStyle?: React.CSSProperties | undefined
}

const FileUploader: React.FC<Props> = (props: Props): JSX.Element => {
    const {
        name,
        types,
        handleChange,
        classes,
        children,
        maxSize,
        minSize,
        onSizeError,
        onTypeError,
        onSelect,
        onDrop,
        multiple,
        required,
        onDraggingStateChange,
        dropMessageStyle,
    } = props
    const labelRef = useRef<HTMLLabelElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState(false)
    const [t, _i18n] = useTranslation()

    const validateFile = (file: File) => {
        if (types && !checkType(file, types)) {
            // types included and type not in them
            setError(true)
            if (onTypeError) onTypeError(t('File type is not supported'))
            return false
        }
        if (maxSize && getFileSizeMB(file.size) > maxSize) {
            setError(true)
            if (onSizeError) onSizeError(t('File size is too large'))
            return false
        }
        if (minSize && getFileSizeMB(file.size) < minSize) {
            setError(true)
            if (onSizeError) onSizeError(t('File size is too small'))
            return false
        }
        return true
    }

    const handleChanges = (files: File | Array<File>): boolean => {
        let checkError = false
        if (files) {
            if (files instanceof File) {
                checkError = !validateFile(files)
            } else {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i]
                    checkError = !validateFile(file) || checkError
                }
            }
            if (checkError) return false
            if (handleChange) handleChange(files)
            setError(false)
            return true
        }
        return false
    }

    const blockEvent = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation()
    }
    const handleClick = (ev: any) => {
        ev.stopPropagation()

        if (inputRef && inputRef.current) {
            inputRef.current.value = ''
            inputRef.current.click()
        }
    }

    const handleInputChange = (ev: any) => {
        const allFiles = ev.target.files
        const files = multiple ? allFiles : allFiles[0]
        const success = handleChanges(files)
        if (onSelect && success) onSelect(files)
    }
    const dragging = useDragging({
        labelRef,
        inputRef,
        multiple,
        handleChanges,
        onDrop,
    })

    useEffect(() => {
        onDraggingStateChange?.(dragging)
    }, [dragging, onDraggingStateChange])

    return (
        <UploaderWrapper
            fullWidth
            overRide={children}
            className={`${classes || ''}`}
            ref={labelRef}
            htmlFor={name}
            onClick={blockEvent}
        >
            <input
                onClick={handleClick}
                onChange={handleInputChange}
                accept={acceptedExt(types)}
                ref={inputRef}
                type='file'
                name={name}
                multiple={multiple}
                required={required}
            />
            {dragging && (
                <HoverMsg style={dropMessageStyle}>
                    <span>{t('Drop Here')}</span>
                </HoverMsg>
            )}
            {!children && (
                <>
                    <UploadRoundedIcon />
                    <DescriptionWrapper error={error}>
                        <span>{t('Upload or drop a file right here')}</span>
                        <DrawTypes types={types} minSize={minSize} maxSize={maxSize} />
                    </DescriptionWrapper>
                </>
            )}
            {children}
        </UploaderWrapper>
    )
}
export default FileUploader

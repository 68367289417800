// TODO: Remove this file

import { OpenAI } from 'openai'

const OPENAI_TOKEN = process.env.REACT_APP_OPENAI_KEY || ''

const baseModelConfig = {
    prompt: 'Fasse den folgenden Text zusammen:\n\n',
    model: 'gpt-3.5-turbo',
    maxTokens: 1500,
    topP: 1,
    n: 1,
    presencePenalty: 0,
    frequencyPenalty: 0,
    temprature: 0.0,
}

export async function summarize(text: string): Promise<string> {
    // init OpenAI
    const openai = new OpenAI({
        apiKey: OPENAI_TOKEN,
        dangerouslyAllowBrowser: true,
    })

    const input = baseModelConfig.prompt + text

    const response = await openai.chat.completions.create({
        messages: [{ role: 'user', content: input }],
        model: baseModelConfig.model,
        temperature: baseModelConfig.temprature,
        max_tokens: baseModelConfig.maxTokens,
        top_p: baseModelConfig.topP,
        n: baseModelConfig.n,
        presence_penalty: baseModelConfig.presencePenalty,
        frequency_penalty: baseModelConfig.frequencyPenalty,
        stream: false,
    })
    return response.choices[0]?.message.content ?? ''
}

import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'

import { getCurrentUserToken, logout } from './services/auth.service'

import Login from './components/Login'
import Register from './components/Register'
import Simplification from './components/Simplification'
import Profile from './components/Profile'

import { T2KRoutes } from './types/general.types'

import EventBus from './common/EventBus'
import ForgotPassword from './components/ForgotPassword'
import { useTranslation } from 'react-i18next'
import { Stack, Switch } from '@mui/material'

type ResponsiveAppBarProps = {
    currentUserToken: string | undefined
    setCurrentUserToken: (token: string | undefined) => void
}

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({ currentUserToken, setCurrentUserToken }) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const { t, i18n } = useTranslation()

    const navigate = useNavigate()

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        href='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Text2Knowledge
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { justify: 'flex-end' } }}>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        ></IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleCloseNavMenu()
                                    navigate('/simplification')
                                }}
                            >
                                {t('Simplification')}
                            </MenuItem>
                        </Menu>
                    </Box>
                    {currentUserToken && <Profile></Profile>}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title='Open settings'>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id='menu-appbar'
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {currentUserToken && (
                                <MenuItem
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate('/simplification')
                                    }}
                                >
                                    {t('Simplification')}
                                </MenuItem>
                            )}
                            {currentUserToken && (
                                <MenuItem
                                    onClick={() => {
                                        setCurrentUserToken(undefined)
                                        logout()
                                        navigate('/login')
                                        handleCloseUserMenu()
                                    }}
                                >
                                    {t('Logout')}
                                </MenuItem>
                            )}
                            {!currentUserToken && (
                                <MenuItem
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate('/login')
                                    }}
                                >
                                    {t('Login')}
                                </MenuItem>
                            )}
                            {!currentUserToken && (
                                <MenuItem
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate('/register')
                                    }}
                                >
                                    {t('Register')}
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 0, ml: 2 }}>
                        <Stack direction='row' spacing={0} alignItems='center'>
                            <Typography variant='h6' component='h3'>
                                en
                            </Typography>
                            <Switch
                                checked={i18n.language === 'de'}
                                color='secondary'
                                inputProps={{ 'aria-label': 'ant design' }}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        i18n.changeLanguage('de')
                                    } else {
                                        i18n.changeLanguage('en')
                                    }
                                }}
                            ></Switch>
                            <Typography variant='h6' component='h3'>
                                de
                            </Typography>
                        </Stack>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

const App: React.FC = () => {
    const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(getCurrentUserToken())
    const [originRoute, setOriginRoute] = useState<T2KRoutes>(T2KRoutes.Base) // eslint-disable-line

    useEffect(() => {
        const userToken = getCurrentUserToken()

        if (userToken) {
            setCurrentUserToken(userToken)
        }

        EventBus.on('logout', logOut)

        return () => {
            EventBus.remove('logout', logOut)
        }
    }, [])

    const logOut = () => {
        logout()
        setCurrentUserToken(undefined)
    }

    return (
        <div>
            <div>
                <ResponsiveAppBar currentUserToken={currentUserToken} setCurrentUserToken={setCurrentUserToken} />
            </div>

            <div className='container mt-3'>
                <Routes>
                    <Route path='/' element={<Simplification currentUserToken={currentUserToken} />} />
                    <Route path='/simplification' element={<Simplification currentUserToken={currentUserToken} />} />
                    <Route
                        path='/login'
                        element={<Login setCurrentUserToken={setCurrentUserToken} originRoute={originRoute} />}
                    />
                    <Route path='/register' element={<Register />} />
                    <Route
                        path='/forgot-password'
                        element={<ForgotPassword currentUserToken={currentUserToken} originRoute={originRoute} />}
                    />
                </Routes>
            </div>
        </div>
    )
}

export default App

export enum SimplificationDecodingStrategy {
    Greedy = 'greedy',
    BeamSeach = 'beam_search',
    ContrastiveSearch = 'contrastive_search',
}

export class SimplificationResponse {
    result: string
    time: number

    constructor(result: string, time: number) {
        this.result = result
        this.time = time
    }
}

export class SimplificationRequest {
    stream: boolean
    inputText: string
    batchSize: number
    decodingStrategy: string
    maxNewTokens: number
    simplificationLevel: string
    separateCompounds: boolean
    filterComplexWords: boolean

    constructor(
        inputText: string,
        stream: boolean = true,
        batchSize: number | undefined = undefined,
        decodingStrategy: SimplificationDecodingStrategy | undefined = undefined,
        maxNewTokens: number | undefined = undefined,
        simplificationLevel: string | undefined,
        separateCompounds: boolean | undefined,
        filterComplexWords: boolean | undefined,
    ) {
        this.stream = stream ?? true
        this.inputText = inputText
        this.batchSize = batchSize ?? 1
        this.decodingStrategy = decodingStrategy ?? SimplificationDecodingStrategy.BeamSeach
        this.maxNewTokens = maxNewTokens ?? 256
        this.simplificationLevel = simplificationLevel ?? 'A1'
        this.separateCompounds = separateCompounds ?? true
        this.filterComplexWords = filterComplexWords ?? false
    }

    public toJSON(): Record<string, any> {
        return {
            stream: this.stream,
            inputText: this.inputText,
            batchSize: this.batchSize,
            decodingStrategy: this.decodingStrategy,
            maxNewTokens: this.maxNewTokens,
            simplificationLevel: this.simplificationLevel,
            separateCompounds: this.separateCompounds,
            filterComplexWords: this.filterComplexWords,
        }
    }

    public toString(): string {
        return JSON.stringify(this.toJSON())
    }
}

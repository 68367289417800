import axios from 'axios'

const AUTH_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/'
const TOKEN_NAME = 't2k-auth-token'

export const login = async (username: string, password: string) => {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    return await axios.post(AUTH_URL + 'login', formData).then((response) => {
        if (response.data.access_token) {
            localStorage.setItem(TOKEN_NAME, response.data.access_token)
        }

        return response.data
    })
}

export const logout = () => {
    localStorage.removeItem(TOKEN_NAME)
}

export const getCurrentUserToken = () => {
    return localStorage.getItem(TOKEN_NAME) || undefined
}

export default function authHeader() {
    const accessToken = getCurrentUserToken()

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken }
    } else {
        return { Authorization: '' }
    }
}

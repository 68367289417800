import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import { resetPassword } from '../services/user.service'
import { T2KRoutes } from '../types/general.types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
    currentUserToken: string | undefined
    originRoute: T2KRoutes
}

const ForgotPassword: React.FC<Props> = ({ currentUserToken, originRoute }) => {
    const [successful, setSuccessful] = useState<boolean | undefined>()
    const [message, setMessage] = useState<string>('')
    const [mail, setMail] = useState<string>('')
    const [t, _i18n] = useTranslation()

    const handleReset = () => {
        resetPassword(mail)
            .then((_resp) => {
                setSuccessful(true)
                setMail('')
                setMessage(t('You have received an email to reset your password.'))
            })
            .catch((_err) => {
                setSuccessful(false)
                setMessage(t('Could not reset password.'))
                setMail('')
            })
    }
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUserToken) {
            navigate(originRoute)
        }
    }, [currentUserToken, originRoute, navigate])

    return (
        <Container component='main'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    {t('Reset Password')}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id='email'
                                label={t('Email Address')}
                                name='email'
                                autoComplete='email'
                                value={mail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setMail(event.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button onClick={handleReset} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        {t('Reset')}
                    </Button>
                    {successful === false && (
                        <>
                            <Alert severity='error'>{message}</Alert>
                        </>
                    )}
                    {successful && <>{<Alert severity='success'>{message}</Alert>}</>}
                </Box>
            </Box>
        </Container>
    )
}

export default ForgotPassword

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslations from './locales/en.json'
import deTranslations from './locales/de.json'

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
    en: enTranslations,
    de: deTranslations,
}

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: 'de',
        fallbackLng: 'de',
        debug: true,
    })

export default i18n

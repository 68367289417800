import axios, { AxiosResponse } from 'axios'
import authHeader from './auth.service'
import { UserResources, User } from '../types/user.types'

axios.defaults.timeout = 30000

const USER_URL = (process.env.REACT_APP_API_URL || 'http://localhost:8000/') + 'user/'

export const createUser = async (username: string, mail: string, password: string) => {
    return await axios.put(USER_URL + 'create', {
        username,
        mail,
        password,
    })
}

export const changePassword = async (oldPassword: string, newPassword: string) => {
    return await axios.patch(USER_URL + 'changePassword', { oldPassword, newPassword }, { headers: authHeader() })
}

export const resetPassword = async (mail: string) => {
    return await axios({
        url: USER_URL + 'resetPassword',
        method: 'PATCH',
        withCredentials: false,
        data: { mail },
    })
}

export const toggleAdmin = async (mail: string) => {
    return await axios.patch(USER_URL + 'toggleAdmin', {
        headers: authHeader(),
        data: { mail },
    })
}

export const toggleEnabled = async (
    mail: string,
    grantedRequests: number,
    tokenLimit: number,
    expirationDate: Date,
) => {
    return await axios.patch(USER_URL + 'toggleEnabled', {
        headers: authHeader(),
        data: { mail, grantedRequests, tokenLimit, expirationDate },
    })
}

export const getUserlist = async (onlyDisabled: boolean, excludeAdmins: boolean): Promise<AxiosResponse<User[]>> => {
    return await axios.get(USER_URL, {
        params: { only_disabled: onlyDisabled, exclude_admins: excludeAdmins },
    })
}

export const getUserResourceInformation = async (): Promise<AxiosResponse<UserResources>> => {
    return await axios.get(USER_URL + 'resources', {
        headers: authHeader(),
    })
}

export const toggleSaveHistory = async () => {
    return await axios.patch(USER_URL + 'toggleSaveHistory', undefined, {
        headers: authHeader(),
    })
}
